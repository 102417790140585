.static-map {
    display: flex;
    flex-direction: row;
}

.static-map img {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 350px;
    object-fit: cover;
}

.directions {
    width: 100%;
    background-color: #f7f7f7;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.directions-box-shadow {
    box-shadow: -1px 0 1px 0px #f7f7f7;
    z-index: 1;
}

.directions .map-info-window {
    padding: 10px 0 10px 10px;
    text-align: center;
}

.directions .get-directions {
    cursor: pointer;
    text-decoration: none;
    border: solid 2px #333333;
    color: #333333;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin: 0.5em 0.5em 0;
    outline: none;
    padding: 0.5em 1em;
}

.directions .get-directions:hover {
    color: #ffffff;
    background-color: #333333;
}
