.ministry-album-carousel-container {
  padding-top: 8px;
  padding-bottom: 8px;
}

.ministry-album-carousel-header {
  text-align: left;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 0px;
}

/* lazy load */
.lazy-load-text {
  color: #cecece !important;
}

/* grid */
.ministry-grid-wrapper {
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(3, 1fr) 320px;
}

.grid-column-1 {
  grid-column: 1 / 4;
  grid-row: 1 / 2;
  margin-right: 4px;
  background: linear-gradient(120deg, rgb(245, 247, 250), rgb(235, 237, 238));
}
.grid-column-2 {
  grid-column: 4;
  grid-row: 1 / 2;

  min-height: 100%;
  overflow: scroll;
  margin-left: 6px;
  margin-right: 6px;
}

.grid-column-3 {
  display: none;
  grid-column: none;
  grid-row: none;
}

@media only screen and (max-width: 1188px) {
  .grid-column-1 {
    grid-column: 1 / 5 !important;
    margin-right: 0px !important;
  }

  .grid-column-2 {
    display: none;
    grid-column: none !important;
    grid-row: none !important;
  }

  .grid-column-3 {
    display: block !important;
    grid-column: 1 / 5 !important;
    grid-row: 3 !important;
  }
}

/* slides */
.container-carousel {
  margin: 8px 8px 16px 8px;
  width: 100%;
  height: inherit;
  padding: 0px;
  display: inline-block; /* so slides are next to each other */
  vertical-align: top;
  white-space: normal; /* to remove parent's white space */

  text-align: center;
  background: #fff;
}

.container-carousel-item-media {
  /* flexDirection: 'column', width: `100%`, height: 200,  */
  flex-direction: column;
  width: 100%;
  height: 200px;
  margin-bottom: 8px;
}

/* slide details */
.container-carousel-div-title {
  font-size: 15px !important;
  text-overflow: ellipsis;
  color: #212121;
}

.column-carousel-item-title {
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
}

.column-carousel-item-image {
  height: inherit;
  width: inherit;
  object-fit: cover;
}

.ministry-column-header {
  text-align: center;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  width: 100%;
}

// added recently on Dec 5, 2023
#mobile-column-container {
  padding: 16px 16px 10px 16px;
}