/* Give */
.give-card-container {
    margin: 20px;
}

.give-details-header {
    margin-left: 16px;
    margin-right: 16px;
}

.give-details-icon {
    color: #252525;
    font-size: 1.18em;
    padding: 0.6em;
}

.give-details-text {
    font-size: 1em;
    padding: 0.6em;
}

.give-details-text a,
.give-details-text a:visited {
    text-decoration: none;
    color: #333333;
}

.give-details-text a:hover {
    text-decoration: underline;
    color: #252525;
}

.give-form {
    padding: 0 7px 0 !important;
    width: auto !important;
}

.give-form div,
.give-form div input {
    padding: 0 7px 0 !important;
}

/* parent - form field */
.give-form-field {
    width: 100%;
    margin-bottom: 20px;
}

/* textfield */
.give-form-textfield {
    width: 100% !important;
    /* 100% of parent (give-form-field) */
}

/* textarea */
.give-form-textfield.textarea div textarea {
    width: 100% !important;
    box-shadow: none !important;
}

/* textfield > hint */
.give-form-textfield div {
    color: rgba(51, 51, 51, 0.8);
    font-family: "Source Sans Pro" !important;
    font-size: 0.9em;
}

/* textfield > input */
.give-form-textfield input {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: "Source Sans Pro" !important;
    font-size: 0.9em;
}

/* textfield > label */
.give-form-textfield label {
    font-family: "Source Sans Pro" !important;
}

/* button */
.give-form-button,
.prayer-request-form-button {
    margin: 0 15px 0 !important;
    width: auto !important;
    color: #333333 !important;

    /* NEW */
    text-transform: none !important;
    font-weight: 600 !important;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0px;
    user-select: none;
    padding-left: 16px;
    padding-right: 16px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 2px;
    display: inline-block;
    min-width: 88px;
    border-color: transparent !important;

    &:hover {
        background-color: rgba(0, 0, 0, 0.08) !important;
    }
}

.give-form-button button {
    background-color: white !important;
}

/* default, success, error button font properties */
.give-form-button button div div span {
    text-transform: none !important;
    font-weight: 600 !important;
}

.give-form-button-success button div span,
.give-form-button-error button div span {
    text-transform: none !important;
    font-weight: 600 !important;
}

/* success, error button styles */
.give-form-button-success button {
    background-color: #4caf50 !important;
}

.give-form-button-success button div div span {
    color: white !important;
}

.give-form-button-error button {
    background-color: #d32f2f !important;
}

.give-form-button-error button div div span {
    color: white !important;
}

/* checkbox */
.give-checkbox {
    margin-bottom: 20px;
    // height: 24px;
}

.give-checkbox div label {
    font-family: "Open Sans" !important;
}

@media only screen and (max-width: 767px) {
    .give-card-container {
        margin: 20px;
    }
    .give-form-field {
        width: 100% !important;
    }
    .give-form-textfield {
        margin: 0 !important;
    }
}

/* lazy-load-text */
.lazy-load-text div label {
    color: #cecece !important;
}

.lazyload-give-checkbox-container {
    height: 132px;
    /* height of 3 checkboxes in lazy load */
    border-radius: 0px;
    position: relative;
    overflow: hidden;
}

/* Prayer request */
.prayer-request-card-container {
    margin: 20px;
}

.prayer-request-details-header {
    margin-left: 16px;
    margin-right: 16px;
}

.prayer-request-details-icon {
    color: #252525;
    font-size: 1.18em;
    padding: 0.6em;
}

.prayer-request-details-text {
    font-size: 1em;
    padding: 0.6em;
}

.prayer-request-details-text a,
.prayer-request-details-text a:visited {
    text-decoration: none;
    color: #333333;
}

.prayer-request-details-text a:hover {
    text-decoration: underline;
    color: #252525;
}

.prayer-request-form {
    padding: 0 7px 0 !important;
    width: auto !important;
}

.prayer-request-form div,
.prayer-request-form div input {
    padding: 0 7px 0 !important;
}

/* parent - form field */
.prayer-request-form-field {
    width: 100%;
    margin-bottom: 20px;
}

/* textfield */
.prayer-request-form-textfield {
    width: 100% !important;
    /* 100% of parent (prayer-request-form-field) */
}

/* textfield > hint */
.prayer-request-form-textfield div {
    color: rgba(51, 51, 51, 0.8);
    font-family: "Source Sans Pro" !important;
    font-size: 0.9em;
}

/* textfield > input */
.prayer-request-form-textfield input {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: "Source Sans Pro" !important;
    font-size: 0.9em;
}

/* textfield > label */
.prayer-request-form-textfield label {
    font-family: "Source Sans Pro" !important;
}

/* button */
.prayer-request-form-button {
    margin: 0 15px 0 !important;
    width: auto !important;
    color: #333333 !important;
}

.prayer-request-form-button button {
    background-color: white !important;
}

/* default, success, error button font properties */
.prayer-request-form-button button div div span {
    text-transform: none !important;
    font-weight: 600 !important;
}

.prayer-request-form-button-success button div span,
.prayer-request-form-button-error button div span {
    text-transform: none !important;
    font-weight: 600 !important;
}

/* success, error button styles */
.prayer-request-form-button-success button {
    background-color: #4caf50 !important;
}

.prayer-request-form-button-success button div div span {
    color: white !important;
}

.prayer-request-form-button-error button {
    background-color: #d32f2f !important;
}

.prayer-request-form-button-error button div div span {
    color: white !important;
}

@media only screen and (max-width: 767px) {
    .prayer-request-card-container {
        margin: 20px;
    }
    .prayer-request-form-field {
        width: 100% !important;
    }
    .prayer-request-form-textfield {
        margin: 0 !important;
    }
}

/* lazy load */

.lazyload-checkbox-label {
    display: flex !important;
    align-items: center !important;
    color: #cecece !important;
    cursor: not-allowed;
}

.lazyload-checkbox-input {
    width: 18px;
    margin-right: 16px !important;
    margin-left: 0px !important;
    height: 18px;
    cursor: not-allowed;
}

.prayer-request-message-error {
    display: block;
}

/* buttons */
.give-form-button-success,
.give-form-button-error,
.prayer-request-form-button-success,
.prayer-request-form-button-error {
    margin: 0 15px 0 !important;
    width: auto !important;
    color: #424242 !important;
    opacity: 1 !important;

    /* NEW */
    text-transform: none !important;
    font-weight: 600 !important;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0px;
    user-select: none;
    padding-left: 16px;
    padding-right: 16px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: none !important;
    border-radius: 2px;
    display: inline-block;
    min-width: 88px;
    border-color: transparent !important;
}

.give-form-button-success,
.prayer-request-form-button-success {
    background-color: #4caf50 !important;
}

.give-form-button-error,
.prayer-request-form-button-error {
    background-color: #d32f2f !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none !important;
}
