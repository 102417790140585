#sub-footer {
    // height: 116px;
    padding: 25px 0;
}

.subfooter-address-container {
    padding-left: 16px;
    padding-right: 8px;
}

.subfooter-address-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.line-1,
    &.line-2 {
        margin-top: 4px;
    }
}

.social-media-icons-container {
    padding-left: 8px;
    padding-right: 16px;
}

.social-media-icons-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding: 0px;
}

.social-media-icon {
    color: #ffffff;
    font-size: 1.18em;
    padding: 0 0.6em;
}
.social-media-icon:hover {
    color: rgb(202, 199, 199);
}

.social-media-icon a {
    color: #d1d1d1;
    transition: 0.1s;
    cursor: pointer;
}
.social-media-icon a:hover {
    color: #ffffff;
    transition: 0.1s;
}
.social-media-icon a:visited {
}

@media only screen and (min-width: 769px) and (max-width: 1188px) {
    .social-media-icon {
        padding: 0 0.38em;
    }

    .subfooter-address-container {
        padding-left: 4px;
        padding-right: 8px;
    }

    .social-media-icons-container {
        padding-left: 8px;
        padding-right: 4px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    #sub-footer {
        height: 150px;
    }

    .social-media-icons-container {
        padding-left: 16px;
        margin-top: 18px;
    }

    .social-media-icons-row {
        justify-content: center;
        padding: 0px;
    }

    .social-media-icon {
        padding: 0 0.35em;
    }

    .subfooter-address-row {
        justify-content: center;
        padding-top: 1px;
        padding-bottom: 1px;
        text-align: center;
    }

    .subfooter-address-container {
        padding-right: 16px;
    }
}

@media only screen and (max-width: 480px) {
    #sub-footer {
        height: 210px;
    }

    .social-media-icon {
        padding: 0 0.35em;
    }

    .social-media-icons-container {
        padding: 0px 20px 0px;
    }

    .social-media-icons-row {
        justify-content: center;
        padding: 20px;
        margin-bottom: 0px;
    }

    .subfooter-address-row {
        justify-content: center;
        padding-top: 1px;
        padding-bottom: 1px;
        text-align: center;

        &.line-2 {
            margin-top: 8px;
        }
    }

    .subfooter-address-container {
        padding: 0px 25px 0px;
        margin-top: 7px;
        margin-bottom: 7px;
    }
}
