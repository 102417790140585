#nav-bar {
    position: fixed;
    z-index: 5;
    /*top:80px;*/
    /* Give top from js */
    width: 100%;
    /* height: 70px; */
    /* Give height from js */
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
    justify-content: space-between;
    /* divide navbar items equally */
    background: rgba(0, 0, 0, 0.3);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease,
        background 0.3s ease;
}

.navbar-shadow {
    box-sizing: border-box;
    /*-webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0px 0px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0px 0px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0px rgba(0, 0, 0, 0.2);*/
    -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 4px,
        rgba(0, 0, 0, 0.05) 0px 1px 1px !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 4px, rgba(0, 0, 0, 0.05) 0px 1px 1px !important;
}

.navbar-is-retracted {
    height: 56px !important;
    transition: inherit;
    -webkit-transform: translateY(-80px);
    transform: translateY(-80px);
}

.navbar-has-gradient {
    background: #333333 !important;
}

.nav {
    height: 100%;
}

.navbar-item-active {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    /* top: 0px;
      border-bottom: 2px solid #ffffff;
      padding-bottom: 0px !important;
      margin-bottom: 2px; */
}

.nav li {
    /*margin-right: 15px;*/
    /* padding-left: 16px;
      padding-right: 16px; */
    height: 100%;
}

ul {
    padding: 0;
}

li {
    list-style-type: inherit;
}

li a {
    position: relative;
    text-decoration: none;
    color: hsla(0, 0%, 100%, 0.8) !important;
    height: 100%;
    transition: 0.3s;
    padding-left: 15px;
    padding-right: 15px;
}

/* gracias for the below on-hover-underline css */
/* http://tobiasahlin.com/blog/css-trick-animating-link-underlines/ */
.navbar-a {
    font-weight: 500;
}

.navbar-a:hover {
    color: #ffffff !important;
    transition: 0.3s;
}

/* on hover drop-down-menu span */
.navbar-a:hover .navbar-dropdown-button-span2 {
    color: #ffffff;
    transition: 0.3s;
}

.navbar-a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.navbar-a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

/* navbar logo, title */
#navbar-logo,
#navbar-title {
    transition: 0.1s;
    opacity: 0;
}

.navbar-logo-display,
.navbar-title-display {
    transition: 0.1s;
    opacity: 1 !important;
}

/* Dropdown menu */
.dropdown {
    position: relative;
    display: inline-block !important;
    transition: 0.3s ease;
}

.dropdown-content {
    font-size: 14px;
    top: 56px;
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 230px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;
    transition: 0.1s ease-out;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation-duration: 0.35s;
    -webkit-animation-duration: 0.35s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

/* NOT BEING USED - to be deleted */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    height: initial !important;
}

.dropdown-content a:hover {
    color: #e29c45;
    background-color: #eeeeee;
    border-radius: 5px;
}

.dropdown:hover .dropdown-content {
    display: block !important;
    transition: 0.1s ease-in;
}

.dropdown:hover {
    /* background-color: rgba(255,255,255,0.9) !important;
      color: #424242 !important; */
}

/* NOT BEING USED - to be deleted */
/* Dropdown menu */
@media only screen and (max-width: 1188px) {
    #nav-bar {
        display: none;
    }
}

/* ---------- responsive navbar ----------- */
.responsive-href {
    position: relative;
    align-items: center;
    height: 100% !important;
    width: 100%;
    display: flex !important;
    justify-content: flex-start;
    align-content: center;
}

@media only screen and (min-width: 769px) and (max-width: 1188px) {
    /* N/A */
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .responsive-href {
        font-size: 17px;
    }
}

@media only screen and (min-width: 360px) and (max-width: 480px) {
    .responsive-href {
        font-size: 16px;
    }
}

@media only screen and (max-width: 359px) {
    .responsive-href {
        font-size: 14px;
    }
}

.navbar-dropdown-button {
    height: 100%;
    /* with respect to li.a */
    border: 10px;
    font-family: Roboto, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    text-decoration: none;
    outline: none;
    font-size: inherit;
    font-weight: inherit;
    position: relative;
    z-index: 1;
    /* min-width: 88px;  */
    color: rgba(0, 0, 0, 0.87);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    border-radius: 2px;
    user-select: none;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0);
    text-align: center;
    text-transform: none;
    padding: 0px 15px 0px;
    /* to make button occupy li a's place*/

    &:focus {
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
    }
}

.navbar-dropdown-button.btn-secondary:hover {
    background-color: transparent !important;
    border: none !important;
}

.navbar-dropdown-button-span1 {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
    pointer-events: none;
}

.navbar-dropdown-button-span2 {
    position: relative;
    /* padding-left: 16px; */
    /* padding-right: 16px; */
    vertical-align: middle;
    letter-spacing: 0px;
    /* text-transform: capitalize; */
    /* font-weight: 500; */
    /* font-size: 14px; */
    color: rgba(255, 255, 255, 0.8);
}

.navbar-dropdown {
    /* remove padding for li a, so that button occupies it's place */
    padding-left: 0px;
    padding-right: 0px;
    height: 100%;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(255, 255, 255, 0.8);
    margin-left: 8px;
    margin-right: -4px;
}

.ministry-dropdown-menu {
    min-width: 280px !important;
    width: 280px;
}

.media-dropdown-menu {
    min-width: 136px !important;
    max-width: 136px;
    width: 168px;
}

.navbar-dropdown-menu-item {
    // to enable 100% height for dropdown menu item like: ODM Wings, Media
    height: 100%;
}