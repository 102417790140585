/* Section container */
.title-description-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
}
.title-description-content {
    height: 100%;
    width: 100%;
    padding: 16px 16px 10px 16px;
}

@media only screen and (max-width: 767px) {
    .title-description-content__mobile {
        padding-bottom: 16px;
    }
}
