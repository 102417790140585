.read-carousel-container {
    padding-top: 8px;
    padding-bottom: 8px;
}

.read-carousel-header {
    text-align: left;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 8px;
    margin-bottom: 0px;
    color: #f58733;
}

.read-carousel-container-content {
    text-align: left;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 8px;
    margin-bottom: 0px;
}

.read-carousel-container-content p {
    font-size: 19px;
}

.read-carousel-container-content .rupee {
    font-size: 24px;
}

.read-carousel-container-images {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 8px;
    margin-bottom: 0px;
}
.read-carousel-container-images img {
    width: 100%;
}

.read-carousel-container-images .lazy-load-container {
    height: 294px;
    cursor: pointer;

    .lazyload-wrapper {
        height: 100%;
    }
}
.read-carousel-container-images .lazy-load-container.section2 {
    height: 350px;
}

.read-carousel-container-images .image-item-padding {
    padding: 8px;
}

.read-carousel-container .tract-title {
    color: #264348;
    font-size: 16px;
}

/* override */
.read-carousel-container-images
    .lazy-load-container
    .lazy-image-container
    .lazy-image-loaded {
    background-size: contain;
}

.read-carousel-container-content.contactus-title {
    text-align: center;
}
.read-carousel-container-content.contactus-subtitle {
    text-align: center;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}
.read-carousel-container-content.contactus-subtitle p {
    width: 100%;
    font-weight: bold;
    margin: 0px 16px 0px 16px;
}

@media only screen and (max-width: 767px) {
    .read-carousel-container-images .image-item-padding {
        padding: 0px;
    }

    .read-carousel-container-content p {
        font-size: 17px;
    }

    .read-carousel-container-content .rupee {
        font-size: 22px;
    }

    .read-carousel-container-images .lazy-load-container {
        height: 200px;
    }

    .read-carousel-container-images .lazy-load-container.section2 {
        height: 250px;
    }

    .read-carousel-container-images
        .lazy-load-container
        .lazy-image-container
        .lazy-image-loaded {
        background-size: cover;
    }
}
