// v0 material ui style sheet
h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: none;
}

ul {
    padding: 0;
}

label {
    margin-bottom: initial !important;
}

.form-control:focus {
    border: none !important;
    box-shadow: none !important;
}

/* reactstrap */
.give-checkbox-label {
    display: flex !important;
    align-items: center !important;
    color: rgba(0, 0, 0, 0.87) !important;
    cursor: pointer;
}

.give-checkbox-input {
    width: 18px;
    margin-right: 16px !important;
    margin-left: 0px !important;
    height: 18px;
    cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: rgba(0, 0, 0, 0.87) !important;
    background-color: rgba(0, 0, 0, 0.87) !important;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 3px !important;
    border-color: rgba(0, 0, 0, 0.87) !important;
    width: 18px !important;
    height: 18px !important;
}

.custom-control-label::after {
    width: 18px !important;
    height: 18px !important;
}

.reactstrap-input-text {
    padding: 0px 8px 0px 0px;
    position: relative;
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.87);
    cursor: inherit;
    font: inherit;
    opacity: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
}

.reactstrap-input-text-hr-container {
    position: relative;
    margin-top: 4px;
    margin-right: 8px;
}

.reactstrap-input-text-hr-1 {
    border-top: none rgb(97, 97, 97);
    border-left: none rgb(97, 97, 97);
    border-right: none rgb(97, 97, 97);
    border-bottom: 1px solid rgb(97, 97, 97);
    bottom: 8px;
    box-sizing: content-box;
    margin: 0px;
    position: absolute;
    width: 100%;
}

.reactstrap-input-text-hr-2 {
    border-top: none rgb(97, 97, 97);
    border-left: none rgb(97, 97, 97);
    border-right: none rgb(97, 97, 97);
    border-bottom: 2px solid rgb(97, 97, 97);
    bottom: 8px;
    box-sizing: content-box;
    margin: 0px;
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.reactstrap-formgroup {
    margin-right: 8px;
    line-height: 24px;
    height: 72px;
}

.invalid-feedback {
    color: rgb(211, 47, 47) !important;
    font-size: 12px !important;
    line-height: 12px;
}

/* FORM CONTROL */
.form-control.is-invalid {
    background-image: none !important;
}

/* BUTTONS */
.btn-outline-secondary:focus {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active {
    background-color: rgba(0, 0, 0, 0.08) !important;
}
/* reactstrap */
