@media only screen and (max-width: 1188px) {
    .main-container {
        margin-top: 56px; /* below Responsive Header(56px) */
    }
}

.image-wrapper {
    background-color: #757575 !important;
    position: relative;
}

.image-wrapper img {
    width: 100%;
}

.text-wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25) !important;
    position: absolute;

    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    color: white;
}

.text-wrapper-title,
.text-wrapper-caption {
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
}

.pagenotfound-nav-icon {
    cursor: pointer;
}
