.autoplay-carousel-container {
    width: 100%;
    height: 550px;
    /* only for this scenario */
    display: block;
    margin-top: 80px !important;
}

.carousel-item {
    height: 100% !important;
}

.carousel-item active {
    background-color: transparent !important;
}

.carousel-image {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    position: absolute;
    transform: translate(0%, -100%);
}

.carousel-image-blur {
    display: block;
    /* filter: blur(6px); */
    height: 100%;
    background-position: center;
    background-repeat: repeat;
    background-size: auto;
    position: relative;
    background-color: #dcdcdc;
    background-image: url("./thumbnail.png");
}

.carousel-inner {
    height: 100% !important;
}

.carousel-control-prev,
.carousel-control-next {
    cursor: pointer;
    transition: 0.3s ease !important;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
    transition: 0.3s ease !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-size: inherit !important;
    background-color: #fff;
    border-radius: 999em;
    width: 48px !important;
    height: 48px !important;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
        0 0 0 1px rgba(0, 0, 0, 0.08), inset 0 0 0 0 rgba(0, 0, 0, 0.1),
        inset 0 0 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08),
        inset 0 0 0 0 rgba(0, 0, 0, 0.1), inset 0 0 0 0 rgba(255, 255, 255, 0.5);
    line-height: 1;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNDEgNy40MUwxNCA2bC02IDYgNiA2IDEuNDEtMS40MUwxMC44MyAxMnoiLz48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+") !important;
    background-color: white;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTAgNkw4LjU5IDcuNDEgMTMuMTcgMTJsLTQuNTggNC41OUwxMCAxOGw2LTZ6Ii8+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==") !important;
    background-color: white;
}

.carousel-indicators li {
    color: transparent;
    width: 5px !important;
    height: 5px !important;
}

.carousel-description-container {
    position: absolute;
    height: 250px;
    width: 250px;
    border-radius: 50%;
    padding: 16px;
    margin: 16px;
    left: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}

.carousel-description {
    font-size: 35px;
    border-radius: 50%;
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-description span {
    padding: 16px;
    text-align: center;
}

@media only screen and (max-width: 1023px) {
    .carousel-description-container {
        height: 100% !important;
        width: 100% !important;
        top: 0% !important;
        left: 0% !important;
        transform: translate(0%, 0%) !important;
        background-color: none !important;
        padding: 0px !important;
        margin: 0px !important;
    }
    .carousel-description {
        margin: 32px;
        width: auto !important;
        height: auto !important;
        border-radius: 0% !important;
        font-size: 32.5px !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1280px) {
    .autoplay-carousel-container {
        height: 490px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .autoplay-carousel-container {
        height: 425px;
    }
    .carousel-description {
        font-size: 30px !important;
    }
}

@media only screen and (min-width: 360px) and (max-width: 480px) {
    .autoplay-carousel-container {
        height: 325px;
    }
    .carousel-description {
        font-size: 30px !important;
    }
}

@media only screen and (max-width: 359px) {
    .autoplay-carousel-container {
        height: 300px;
    }
    .carousel-description {
        font-size: 25px !important;
    }
}
