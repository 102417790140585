.literature-carousel-container {
    padding-top: 8px;
    padding-bottom: 8px;
}

.literature-carousel-header {
    text-align: left;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 8px;
    margin-bottom: 0px;
    color: #f58733;
}

.pdf-viewer-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    background-color: #d1d1d1;
    overflow-y: hidden;
    transition: 0.5s;
}

.pdf-sticky-header {
    position: fixed;
    width: 100%;
    top: 0;
}

.pdf-close-button,
.pdf-download-button {
    background-color: rgba(0, 0, 0, 0.8) !important;
    margin: 12px !important;
    border: 0px !important;
    height: 43px !important;
    width: 43px !important;
}
.pdf-close-button div svg {
    height: 28px !important;
    width: 28px !important;
}
.pdf-download-button div svg {
    height: 22px !important;
    width: 22px !important;
}

.literature-feedback-container {
    background: linear-gradient(120deg, #f5f7fa, #ebedee);
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

/* PDF iframe */
.ndfHFb-c4YZDc-K9a4Re {
    background: rgba(0, 0, 0, 0.75) !important;
}
