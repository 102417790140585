#main-footer {
    padding: 20px 0;
}

.main-footer-row-1 {
    margin-top: 8px;
    margin-bottom: 8px;
}

/* row-1 */
.main-footer-navigation {
    margin-top: 16px;
}
.main-footer-navigation li {
    padding-bottom: 10px;
    list-style-type: none !important;
}

.main-footer-navigation li a,
.main-footer-navigation li span {
    font-size: 0.95em;
    cursor: pointer;
    text-decoration: none;
    color: #909090 !important;
    padding: 0 15px 0 !important;
    transition: 0.1s !important;
}

.main-footer-navigation li span {
    color: #f58733 !important;
}

.main-footer-navigation li a:hover {
    color: #ffffff !important;
    transition: 0.1s !important;
}

/* li div (textfield)*/
.main-footer-navigation li div {
    /* padding: 0 7px 0 !important;
  width: 150px !important;  */
}

.main-footer-navigation li div div,
.main-footer-navigation div input {
    /* padding: 0 7px 0 !important;  */
}

/* textfield > hint */
.main-footer-navigation-textfield div {
    color: rgba(238, 238, 238, 0.9) !important;
    font-family: "Open Sans" !important;
    font-size: 0.9em;
}

/* textfield > input */
.main-footer-navigation-textfield input {
    color: white !important;
    font-family: "Lato Light" !important;
    font-size: 0.9em;
}

/* button */
.main-footer-navigation-button {
    margin: 0 15px 0 !important;
    width: 160px !important;
    color: rgba(238, 238, 238, 0.9) !important;
}

/* li a's header */
.main-footer-navigation-header {
    cursor: text !important;
    font-size: 1.25em !important;
    margin-bottom: 10px;
    /* show/hide header color */
    /* color: rgba(242, 243, 237, 1) !important; */
    color: #f58733 !important;
}

/* row-1 */
/* row-2 */
.main-footer-logo-container {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.responsive-main-footer-logo-container {
    display: none;
}

.main-footer-logo,
.responsive-main-footer-logo {
    margin: 0 8px 0;
}

.main-footer-logo div {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
}

.main-footer-logo-row,
#main-footer-logo-img,
#responsive-main-footer-logo-img {
    height: 147px;
    // width: 147px;
    border-radius: 5%;
    margin: 0 8px 0 0;
    padding: 6px;
}

#responsive-main-footer-logo-img {
    /* THE COMPLETE GUIDE TO CENTERING A DIV - http://www.tipue.com/blog/center-a-div/ */
    margin: 0 auto;
    width: auto;
}

.main-footer-title-row {
    margin: 0 0 0 8px;
    float: left;
    font-size: 1.45em;
}

.main-footer-title {
    font-size: 2em;
}

.main-footer-copyright {
    font-size: 0.7em;
    padding: 0 8px 0;
    margin-left: 32px;
}

.main-footer-copyright-row {
    margin-top: 8px;
}

/* row-2 */
@media only screen and (min-width: 769px) and (max-width: 1188px) {
    .main-footer-logo {
        margin: 0 8px 0;
    }
    .main-footer-title-row {
        font-size: 1.3em;
    }
    .main-footer-logo-row {
        height: 110px;
        width: 110px;
    }
}

@media only screen and (min-width: 1025px) {
    .main-footer-navigation li a,
    .main-footer-navigation li span {
        padding: 0 13px 0 !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    #main-footer-logo-img {
        height: auto !important;
        width: auto !important;
    }

    .main-footer-navigation li a {
        padding: 0 3px 0 !important;
    }

    .main-footer-navigation li span {
        padding: 0 3px 0 !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    #main-footer-logo-img {
        height: auto !important;
        width: auto !important;
    }

    .main-footer-navigation li a {
        padding: 0 3px 0 !important;
        font-size: 0.85em !important;
    }

    .main-footer-navigation li span {
        padding: 0 3px 0 !important;
        font-size: 1em !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
    /* skip 768px for ipad */
    .main-footer-logo-container {
        display: none;
    }
    .responsive-main-footer-logo-container {
        display: block;
    }
    .main-footer-logo {
        margin: 0 8px 0;
    }
    .main-footer-logo div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
    .main-footer-title-row {
        font-size: 1.2em;
        margin: 0 0 0 0;
    }
    .main-footer-logo-row {
        height: 100px;
        width: 100px;
        margin: 0 0 0 0;
    }
    .main-footer-copyright {
        margin-left: 0px;
    }
    .main-footer-copyright-row {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 480px) {
    .main-footer-logo-container {
        display: none;
        justify-content: center;
    }
    .responsive-main-footer-logo-container {
        display: block;
    }
    .main-footer-logo {
        margin: 0 8px 0;
    }
    .main-footer-logo div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
    .main-footer-title-row {
        font-size: 1.1em;
        margin: 0 0 0 0;
    }
    .main-footer-logo-row {
        height: 90px;
        width: 90px;
        margin: 0 0 0 0;
    }
    .main-footer-copyright {
        margin-left: 0px;
    }
    .main-footer-copyright-row {
        margin-top: 0px;
    }
}

/* footer feedback */
form.footer {
    width: 100%;
}

form.footer .footer-field input[type="text"],
textarea {
    background: #333333;
    color: #f5f5f5;
    font: inherit;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    border: 0;
    outline: 0;
    padding: 2px 4px 2px 4px;
    /* margin: 0 10px 0 !important; */
    height: 22px;
    font-size: 0.9em;
    width: 220px !important;
    margin-left: 14px;
}

form.footer .footer-field textarea {
    height: 40px;
    resize: vertical;
    width: 220px !important;
    margin-left: 14px !important;
    resize: none !important;
}

form.footer .footer-field {
    margin-bottom: 6px;
    /* width: 100% !important; */
}

form.footer .footer-button {
    width: 220px !important;
    margin-left: 14px;
}

form.footer .footer-button button {
    background: #333333;
    font: inherit;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    border: 0;
    outline: 0;
    cursor: pointer;
    margin-top: 8px;
    display: flex;
    align-items: baseline;

    &:disabled {
        &:hover {
            cursor: not-allowed;
        }
    }
}

form.footer .footer-button label {
    color: #909090;
    padding: 4px;
    height: 28px;
    font-size: 0.9em;
    cursor: pointer;
    display: flex;
    align-items: center;
}

form.footer .footer-button label.disabled {
    cursor: not-allowed;
}

form.footer .footer-button:disabled {
    cursor: not-allowed !important;
}

form.footer .footer-field span.error-text,
form.footer .footer-field span.success-text {
    width: 76% !important;
    margin: 0 8px !important;
    padding: 8px 0 !important;
    font-size: 0.75em;
    display: block;
    height: 15px;
    font-weight: bold;
}

form.footer .footer-field span.error-text {
    color: #d32f2f !important;
    text-align: center;
}

form.footer .footer-field span.success-text {
    color: #f58733 !important;
    text-align: center;
}

@media only screen and (min-width: 786px) and (max-width: 1024px) {
    form.footer .footer-field input[type="text"],
    form.footer .footer-field textarea {
        margin: 0 !important;
        margin-left: 5px !important;
    }
}

@media only screen and (max-width: 768px) {
    form.footer .footer-field {
        margin-bottom: 6px;
        width: auto !important;
    }
    form.footer .footer-field input[type="text"],
    form.footer .footer-field textarea {
        width: 97% !important;
        margin: 0 !important;
        margin-left: 5px !important;
    }
    form.footer .footer-button {
        width: auto !important;
    }
    form.footer .footer-field span.error-text,
    form.footer .footer-field span.success-text {
        width: 100% !important;
        margin: 0 !important;
    }
}

@media only screen and (min-width: 200px) and (max-width: 480px) {
    form.footer .footer-field input[type="text"],
    form.footer .footer-field textarea {
        width: 92% !important;
        margin-left: 16px !important;
    }
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
    #responsive-main-footer-logo-img {
        height: 147px;
    }
}
