/* appbar styles */
/* app-bar */
.appbar-shadow {
    box-sizing: border-box;
    /* box-shadow */
    -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 5px,
        rgba(0, 0, 0, 0.05) 0px 1px 1px !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 5px, rgba(0, 0, 0, 0.05) 0px 1px 1px !important;
}

.appbar {
    /* height: 60px;  */
    /* set it from js */
    padding-top: 13px;
    padding-bottom: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 5;
    position: fixed;
    top: 0;
    width: 100%;
    /* justify-content: space-between; divide navbar items equally */
    background-color: white;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

/* header */
.header-logo {
    height: 54px;
}

.header-right-menu-container {
    display: flex;
    padding: 0px 0px 0px 8px;
}

.header-right-menu-container div .menu-icon {
    transition: 0.1s ease;
    color: #4d4f53;
    margin-right: 4px;
}

.header-right-menu-container div .menu-item {
    color: #4d4f53;
    margin-right: 20px;
    font-size: 15px;
}

.header-right-menu-container div .menu-item a {
    cursor: pointer;
}

.header-right-menu-container div .menu-item a:hover {
    text-decoration: underline;
}

.header-right-menu-container div:hover .menu-icon.give {
    color: #81c784;
    transition: 0.1s ease;
}

.header-right-menu-container div:hover .menu-icon.home {
    color: #3665f3;
    transition: 0.1s ease;
}

.header-right-menu-container div:hover .menu-icon.contactus {
    color: #ff5252;
    transition: 0.1s ease;
}

/* header */
/* transitions */
.slide-down {
    -webkit-transform: translateY(60%);
    transform: translateY(60%);
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.slide-up {
    -webkit-transform: translateY(-80px);
    transform: translateY(-80px);
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

/* app header logo, title, caption */
#header-logo,
#header-title,
#header-caption {
    transition: 0.3s;
    opacity: 1;
}

.header-logo-hide,
.header-title-hide,
.header-caption-hide {
    opacity: 0 !important;
    transition: 0.3s;
}

/* app header logo, title, caption */
.appbar-overflow-icon {
    display: flex;
    flex-flow: row wrap;
    /* This aligns items to the end line on main-axis */
    justify-content: flex-start;
    padding-left: 16px;
    transition: 0.2s;
}

.appbar-logo {
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.appbar-logo img {
    height: 62px;
}

.appbar-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.appbar-title {
    padding: 4px;
}

.appbar-description {
    padding: 4px;
}

.appbar-title-content {
    font-size: 26px;
}

.appbar-description-content {
    font-size: 14px;
}

.responsive-appbar-title-content {
    /* font-size: 20px; */
    text-align: center;
    padding: 4px;
}

.responsive-appbar-description-content {
    /* font-size: 15px; */
    text-align: center;
    padding: 8px 10px 8px;
}

@media only screen and (min-width: 769px) and (max-width: 1188px) {
    .responsive-appbar-title-content {
        font-size: 20px;
    }
    .responsive-appbar-description-content {
        font-size: 16px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .responsive-appbar-title-content {
        font-size: 19px;
    }
    .responsive-appbar-description-content {
        font-size: 16px;
    }
    .responsive-submenu-button-text {
        font-size: 17px;
    }
}

@media only screen and (min-width: 360px) and (max-width: 480px) {
    .responsive-appbar-title-content {
        font-size: 17px;
    }
    .responsive-appbar-description-content {
        font-size: 15px;
    }
    .responsive-submenu-button-text {
        font-size: 16px;
    }
}

@media only screen and (min-width: 326px) and (max-width: 359px) {
    .responsive-appbar-title-content {
        font-size: 16px;
    }
    .responsive-appbar-description-content,
    .responsive-submenu-button-text {
        font-size: 14px;
    }
}

@media only screen and (max-width: 325px) {
    /* smaller screens */
    .responsive-appbar-title-content {
        font-size: 15px;
    }
    .responsive-appbar-description-content,
    .responsive-submenu-button-text {
        font-size: 13px;
    }
}

/* responsive-appbar */
.responsive-appbar-a:visited {
    color: #000000;
}

#responsive-app-bar {
    display: none;
    z-index: 50;
    position: fixed;
    width: 100%;
    top: 0;
    background: white;
    transition: 0.5s;
}

.responsive-appbar {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.responsive-appbar-shadow {
    transition: 0.5s !important;
    box-sizing: border-box;
    /* box-shadow */
    -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 5px,
        rgba(0, 0, 0, 0.05) 0px 1px 1px !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 5px, rgba(0, 0, 0, 0.05) 0px 1px 1px !important;
}

.responsive-appbar-logo {
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.responsive-appbar-logo a img {
    height: 40px;
    padding: 10px;
}

/* app-bar & responsive-app-bar - media query*/
@media only screen and (max-width: 1188px) {
    #app-bar {
        display: none;
    }
    #responsive-app-bar {
        display: block !important;
    }
}

/* app-bar */
/* mobile nav icon */
#nav-icon {
    z-index: 50;
    width: 18px;
    height: 18px;
    position: relative;
    margin: 5px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

#nav-icon span {
    display: block;
    position: relative;
    height: 2px;
    width: 100%;
    background: #757575;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

.nav-icon-color-on-menu-open {
    background: #424242 !important;
}

#nav-icon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon span:nth-child(2) {
    top: 4px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon span:nth-child(3) {
    top: 8px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0px;
    left: 2px;
}

#nav-icon.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 9px;
    left: 2px;
}

#responsive-menu i {
    float: left;
    cursor: pointer;
}

.responsive-menu-open {
    position: absolute;
    width: 100%;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    transition: 0.5s ease !important;
    /*display: block;
      -webkit-font-smoothing: antialiased;*/
}

.responsive-menu-close {
    position: absolute;
    top: -250px;
    left: 0;
    width: 100%;
    padding: 0px;
    transition: 0.5s ease !important;
    overflow: hidden;
    /*display: none;
    -webkit-font-smoothing: antialiased;*/
}

#responsive-menu a {
    text-decoration: none;
    display: block;
    float: left;
    clear: left;
    padding: 0.5em 0;
}

.responsive-menu-open {
    display: flex;
    flex-direction: column;
    transition: 1s !important;
}

@media only screen and (min-width: 1189px) {
    .overlay {
        display: none;
    }
}

/* overlay */
.overlay {
    z-index: 40;
    position: fixed !important;
    background-color: white !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    transition: opacity 0.25s, visibility 0.25s, height 0.25s;
    overflow: hidden;
}

.overlay.open {
    opacity: 1;
    visibility: visible;
    height: 100%;
}

/* 12 items in menu */
.overlay.open li {
    animation: fadeIn 0.2s ease forwards;
    animation-delay: 0.2s;
    display: flex;
}

.overlay.open li:nth-of-type(2) {
    animation-delay: 0.25s;
}

.overlay.open li:nth-of-type(3) {
    animation-delay: 0.25s;
}

.overlay.open li:nth-of-type(4) {
    animation-delay: 0.25s;
}

.overlay.open li:nth-of-type(5) {
    animation-delay: 0.25s;
}

.overlay.open li:nth-of-type(6) {
    animation-delay: 0.25s;
}

.overlay.open li:nth-of-type(7) {
    animation-delay: 0.25s;
}

.overlay.open li:nth-of-type(8) {
    animation-delay: 0.25s;
}

.overlay.open li:nth-of-type(9) {
    animation-delay: 0.25s;
}

.overlay.open li:nth-of-type(10) {
    animation-delay: 0.25s;
}

.overlay.open li:nth-of-type(11) {
    animation-delay: 0.25s;
}

.overlay.open li:nth-of-type(12) {
    animation-delay: 0.25s;
}

.overlay-content {
    height: 100%;
    overflow: scroll;
}

.overlay nav {
    position: relative;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 17px;
    font-family: "Roboto", sans-serif;
    font-weight: 200;
    text-align: center;
}

.overlay ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
}

.overlay ul li {
    /* display: block; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    min-height: 30px;
    position: relative;
    opacity: 0;
}

.li-responsive-appbar-no-hover:hover {
    background-color: #fff !important;
}

.overlay ul li a {
    display: inherit;
    position: relative;
    color: #424242 !important;
    text-decoration: none;
    overflow: hidden;
    padding-left: 50px;
    padding-right: 50px;
}

.overlay ul li a:hover:after,
.overlay ul li a:focus:after,
.overlay ul li a:active:after {
    width: 100%;
    font-weight: 300;
}

.responsive-active {
    background-color: #eeeeee;
}

.responsive-menuitem-active {
    font-weight: 300;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        left: 0%;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}

/* overlay - submenu */
.overlay ul ul {
    display: none;
}

/* responsive sub menu */
.responsive-submenu-close {
    display: none;
    transition: 0.3s;
}

.responsive-submenu-open {
    display: block;
    transition: 0.3s;
    background-color: #f9f9f9;
}

.responsive-submenu-button {
    height: 100% !important;
    width: 100% !important;
    align-content: center !important;
    /* for safari */
    padding-left: 50px !important;
    padding-right: 40px !important;
}

.responsive-submenu-button-row {
    z-index: -1;
}

.responsive-submenu-button-text {
    transition: 0.3s;
}

.responsive-submenu-item {
    padding-left: 70px !important;
}

/* responsive sub menu button */
.closed .vertical {
    transition: all 0.3s ease-in-out;
    transform: rotate(-90deg);
}

.closed .horizontal {
    transition: all 0.3s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
}

.opened {
    opacity: 1;
}

.opened .vertical {
    transition: all 0.3s ease-in-out;
    transform: rotate(90deg);
}

.opened .horizontal {
    transition: all 0.3s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
}

.circle-plus {
    height: 4px;
    width: 4px;
    font-size: 2px;
    opacity: 1;
    /* this element should be non-clickable, as whole Button should be clickable */
    z-index: -1;
    /* move icon to middle of button */
    margin-top: 8px;
    margin-bottom: 8px;
}

.circle-plus .circle {
    position: relative;
    width: 2px;
    height: 2px;
    border-radius: 100%;
    /* border: solid .5em #DFDAD7; */
}

.circle-plus .circle .horizontal {
    position: absolute;
    background-color: #757575;
    width: 12px;
    height: 2px;
    left: 60%;
    margin-left: -2.8em;
    top: 50%;
    margin-top: 0px;
}

.circle-plus .circle .vertical {
    position: absolute;
    background-color: #757575;
    width: 2px;
    height: 12px;
    left: 50%;
    margin-left: 0em;
    top: 50%;
    margin-top: -2.5em;
}

/* appbar styles */
