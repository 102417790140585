html {
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
}

body {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    transition: all 0.2s linear;
    margin: 0;
    padding: 0;
    background-color: #fdfdfd;

    font-size: initial !important;
    font-weight: 400 !important;
    line-height: initial !important;
    color: initial !important;
    text-align: initial !important;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}

* {
    outline: none;
    box-sizing: border-box;
}

.wrapper {
    padding: 0 16px;
}

.custom-container {
    max-width: 1140px;
    margin: 0 auto;
}

/* main container */
.main-container {
    margin-top: 80px; /* below Header(80px) */
}

@media only screen and (max-width: 1188px) {
    .main-container {
        margin-top: -24px; /* below Responsive Header(56px) */
    }
}

img {
    max-width: 100%;
    display: block;
}

button {
    // border-style: solid;
    // border-width: 1px;

    &:focus {
        outline: 0;
    }
}

.navbar {
    padding: 0 !important;
}

.dropdown {
    .show {
        color: rgba(0, 0, 0, 0.87);
        transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms,
            opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        box-sizing: border-box;
        font-family: Roboto, sans-serif;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        border-radius: 2px;
        z-index: 2100;
        transform: scaleY(1);
        transform-origin: left top;
        max-height: 594px;
        top: 0px;
        left: 0px;
    }
}

.dropdown-menu {
    border: none !important;
}

.dropdown-menu.show {
    pointer-events: auto !important;
    opacity: 1 !important;
    padding: 8px 0px;
    display: table-cell;
    user-select: none;
    width: 280px;
    top: 35px !important;
    transform: none !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

.dropdown-item {
    position: relative;

    border: 10px;
    box-sizing: border-box;
    display: block;
    font-family: Roboto, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    text-decoration: none;
    margin: 0px;
    padding: 0px 16px;
    outline: none;
    font-size: 16px;
    font-weight: inherit;
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    line-height: 48px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    min-height: 48px;
    white-space: nowrap;
    background: none;
}

.dropdown-item .menu-icon {
    position: absolute;
    top: 2px;
    margin: 12px;
    right: 4px;
    display: block;
    color: rgb(117, 117, 117);
    fill: rgb(117, 117, 117);
    height: 20px;
    width: 20px;
    user-select: none;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.lazyload-wrapper {
    height: 100% !important;
    width: 100% !important;
}