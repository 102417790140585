/* blink */
@-webkit-keyframes blinker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.blink {
    text-decoration: blink;
    -webkit-animation-name: blinker;
    -webkit-animation-duration: 0.6s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-direction: alternate;
}

/* button 3 dots spinner - https://codepen.io/danielmorosan/pen/XmYBVx */
.spinner {
    display: none; /* controlled dynamically from code (used along with buttons) */
    text-align: center;
    padding: 0 !important;
    width: auto !important;
}

.spinner > div {
    width: 4px;
    height: 4px;
    background-color: rgb(144, 144, 144);

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1s infinite ease-in-out both;
    animation: sk-bouncedelay 1s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
