.new-video-gallery {
  .section-header {
    text-align: center;
    margin: 60px auto 20px auto;

    font-family: "Montserrat", sans-serif;
    font-size: 36px;
    font-weight: 400;
    text-transform: uppercase;
    color: #222;
  }

  .section-header-underline {
    border: 1px solid #222;
    width: 3rem;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .video-gallery {
    position: relative;
    margin: 0 auto;
    max-width: 1000px;
    text-align: center;
  }

  .video-gallery .gallery-item {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 320px;
    max-width: 580px;
    max-height: 360px;
    width: 48%;
    background: #000;
    cursor: pointer;
  }

  .video-gallery .gallery-item img {
    position: relative;
    display: block;
    opacity: 0.45;
    width: 105%;
    height: 260px;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(-5px, 0, 0);
    backface-visibility: hidden;
  }

  .video-gallery .gallery-item .gallery-item-caption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
  }

  .video-gallery .gallery-item .gallery-item-caption,
  .video-gallery .gallery-item .gallery-item-caption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video-gallery .gallery-item h2 {
    font-weight: 400;
    font-size: 24px;
    overflow: hidden;
    padding: 0.5em 0;
  }

  .video-gallery .gallery-item h2,
  .video-gallery .gallery-item p {
    position: relative;
    margin: 0;
    z-index: 1;
  }

  .video-gallery .gallery-item p {
    letter-spacing: 1px;
    font-size: 68%;

    padding: 1em 0;
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(10%, 0, 0);
  }

  .video-gallery .gallery-item:hover img {
    opacity: 0.3;
    transform: translate3d(0, 0, 0);
  }

  .video-gallery .gallery-item .gallery-item-caption {
    text-align: left;
  }

  .video-gallery .gallery-item h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 15%;
    height: 1px;
    background: #fff;

    transition: transform 0.3s;
    transform: translate3d(-100%, 0, 0);
  }

  .video-gallery .gallery-item:hover h2::after {
    transform: translate3d(0, 0, 0);
  }

  .video-gallery .gallery-item:hover p {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @media screen and (max-width: 50em) {
    .video-gallery .gallery-item {
      display: inline-block;
      float: none;
      margin: 10px auto;
      width: 100%;
    }
  }
}
