#lazyload-sub-footer {
    height: 115px; /* 66px + 25px */
    background-color: rgba(51, 51, 51, 1); /* rgba(242, 243, 237, 1) */
}

#lazyload-main-footer {
    height: 230px;
    background-color: rgba(37, 37, 37, 1);
    padding: 3em 0;
}

@media only screen and (min-width: 769px) and (max-width: 1188px) {
    #lazyload-sub-footer {
        height: 115px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    #lazyload-sub-footer {
        height: 125px;
    }
}

@media only screen and (max-width: 480px) {
    #lazyload-sub-footer {
        height: 185px;
    }
}
