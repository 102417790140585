.roboto {
    font-family: $font-roboto !important;
    font-weight: 400;
}

.roboto-thin {
    font-family: $font-roboto !important;
    font-weight: 100;
}

.roboto-light {
    font-family: $font-roboto !important;
    font-weight: 300;
}

.roboto-bold {
    font-family: $font-roboto !important;
    font-weight: 700;
}

.roboto-slab {
    font-family: $font-roboto-slab !important;
    font-weight: 400;
}

.roboto-condensed {
    font-family: $font-roboto-condensed !important;
    font-weight: 400;
}

.open-sans {
    font-family: $font-open-sans !important;
    font-weight: 400;
}

.open-sans-semibold {
    font-family: $font-open-sans !important;
    font-weight: 600;
}

.noto-sans {
    font-family: $font-noto-sans !important;
    font-weight: 400;
}

.source-sans-pro {
    font-family: $font-source-sans-pro !important;
    font-weight: 400;
}

.lato {
    font-family: $font-lato !important;
    font-weight: 400;
}

.lato-light {
    font-family: $font-lato !important;
    font-weight: 300;
}
