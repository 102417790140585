.ios-safari-no-overflow {
  height: 100%;
  width: 100%;
  position: fixed;
}
@media only screen and (min-width: 1189px) {
  .ios-safari-no-overflow {
    /* 
            However, "position: fixed" also has a slight side-affect of essentially scrolling to the top.  
            "position:absolute" resolves this but, re-introduces the ability to scroll on mobile.
            Thus, we use "responsiveDynamicNoOverflow" function with help of "platform" lib to resolve this issue.
  
            position: absolute !important;
        */
  }
}

.icon-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* list elements style */
li {
  list-style-type: square !important;
}

/* flex-box */
.display-flex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

.display-flex-end {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  align-items: center;
}

.display-flex-start {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}
/* flex-box */

/* textarea */
textarea {
  -webkit-transition: background 85ms ease-in, border-color 85ms ease-in;
  transition: background 85ms ease-in, border-color 85ms ease-in;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: 3px;
  display: block;
}
textarea:focus {
  outline: none !important;
}
/* textarea */

/* background image */
.background-image-full {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-attachment: fixed !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.background-size-contain {
  background-size: contain !important;
}

.twoCardsInARow {
  margin-bottom: 8px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.text-transform-capitalize {
  text-transform: capitalize !important;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-wrap {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* height width */
.height-100 {
  height: 100%;
}
.width-100 {
  width: 100%;
}

/* box shadow for carousel */
.boxshadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  /* -webkit-transition: box-shadow 200ms cubic-bezier(0.4,0.0,0.2,1);
  transition: box-shadow 200ms cubic-bezier(0.4,0.0,0.2,1); */
}

.boxshadow:hover {
  box-shadow: 0.5px 2.5px 2.5px 0.5px rgba(0, 0, 0, 0.16),
    0 0 0 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.boxshadow-border-radius-small {
  border-radius: 2px;
}
.boxshadow-border-radius-medium {
  border-radius: 5px;
}

/* snacbar */
.snackbar div div span {
  color: rgba(255, 255, 255, 1) !important;
}

/* material-ui-button */
.material-ui-button-text-transform div span {
  text-transform: none !important;
  font-weight: 600 !important;
}

/* main container */
.main-container {
  margin-top: 80px; /* below Header(80px) */
}

@media only screen and (max-width: 1188px) {
  .main-container {
    margin-top: -24px; /* below Responsive Header(56px) */
  }
}

/* custom scroll bar */
.custom-vertical-scrollbar::-webkit-scrollbar {
  width: 8px; /* for vertical scrollbars */
  height: 12px; /* for horizontal scrollbars */
  background: rgba(0, 0, 0, 0);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  position: absolute;
  opacity: 0;
}
.custom-vertical-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.custom-vertical-scrollbar::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  position: absolute;
}

.custom-horizontal-scrollbar::-webkit-scrollbar {
  width: 7px; /* for vertical scrollbars */
  height: 12px; /* for horizontal scrollbars */
  background: rgba(0, 0, 0, 0);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  position: absolute;
  opacity: 0;
}

.custom-horizontal-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.custom-horizontal-scrollbar::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  position: absolute;
}

.text-magenta {
  color: rgb(245, 135, 51) !important;
}

.linear-bg-grey {
  background: #434343;
}
.linear-bg-text-grey {
  color: rgb(209, 209, 209);
}

.linear-gradient-bg-grey {
  background: linear-gradient(120deg, rgb(245, 247, 250), rgb(235, 237, 238));
}

/* envelope border */
.envelope-border {
  padding: 1em;
  border: 16px solid transparent;
  border-image: 16
    repeating-linear-gradient(
      -45deg,
      red 0,
      red 1em,
      transparent 0,
      transparent 2em,
      #58a 0,
      #58a 3em,
      transparent 0,
      transparent 4em
    );

  // max-width: 20em;
  width: 450px !important;
  //   font: 100%/1.6 Baskerville, Palatino, serif;
  font: 100%/1.6 serif;
}
