.read-form {
  padding: 0 7px 0 !important;
  width: auto !important;
}
.read-form div,
.read-form div input {
  padding: 0 7px 0 !important;
}

/* parent - form field */
.read-form-field {
  width: 100%;
  margin-bottom: 8px;
}
/* textfield */
.read-form-textfield {
  width: 100% !important; /* 100% of parent (read-form-field) */
  margin-top: 14px;
}
/* textfield > hint */
.read-form-textfield div {
  color: rgba(51, 51, 51, 0.8);
  font-family: "Source Sans Pro" !important;
  font-size: 0.9em;
}
textarea {
  margin-left: 0px !important;
}
/* textfield > input */
.read-form-textfield input {
  color: rgba(0, 0, 0, 1) !important;
  font-family: "Source Sans Pro" !important;
  font-size: 0.9em;
}
/* textfield > label */
.read-form-textfield label {
  font-family: "Source Sans Pro" !important;
}

/* textarea */
.read-form-textfield.textarea div textarea {
  width: 100% !important;
  box-shadow: none !important;
}

/* button */
.read-form-button {
  margin: 0 15px 0 !important;
  width: auto !important;
  color: rgba(51, 51, 51, 1) !important;

  /* NEW */
  text-transform: none !important;
  font-weight: 600 !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  user-select: none;
  padding-left: 16px;
  padding-right: 16px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 2px;
  display: inline-block;
  min-width: 88px;
  border-color: transparent !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
}
.read-form-button button {
  background-color: white !important;
}
/* default, success, error button font properties */
.read-form-button button div div span {
  text-transform: none !important;
  font-weight: 600 !important;
}
.read-form-button-success button div span,
.read-form-button-error button div span {
  text-transform: none !important;
  font-weight: 600 !important;
}
/* success, error button styles */
.read-form-button-success button {
  background-color: #4caf50 !important;
}
.read-form-button-success button div div span {
  color: white !important;
}
.read-form-button-error button {
  background-color: #d32f2f !important;
}
.read-form-button-error button div div span {
  color: white !important;
}

/* NEW success, error button styles */
.read-form-button-success,
.read-form-button-error {
  margin: 0 15px 0 !important;
  width: auto !important;
  color: #424242 !important;
  opacity: 1 !important;

  /* NEW */
  text-transform: none !important;
  font-weight: 600 !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  user-select: none;
  padding-left: 16px;
  padding-right: 16px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: none !important;
  border-radius: 2px;
  display: inline-block;
  min-width: 88px;
  border-color: transparent !important;
}

.read-form-button-success {
  background-color: #4caf50 !important;
}

.read-form-button-error {
  background-color: #d32f2f !important;
}
/* NEW success, error button styles */

/* drop down menu */
.read-dropdown-menu {
  height: auto !important;
  margin-right: 8px;
}
.read-dropdown-menu div div {
  padding: 0px !important;
  margin: 0px !important;
  border-color: rgb(97, 97, 97) !important;
}

.read-dropdown {
  width: 80%;
  margin-bottom: 0px !important;
  margin-top: 23px;

  .dropdown {
    width: 100% !important;
    .dropdown-menu {
      &.show {
        width: calc(100% - 15px) !important;
        top: 45px !important;

        .dropdown-item {
          min-height: 44px !important;
          height: 44px !important;
        }
      }
    }
  }
}
.read-quantity {
  width: 20%;
  margin-bottom: 0px !important;
}

.read-dropdown-button {
  height: 100%;
  width: 100%;
  /* with respect to li.a */
  border: 10px;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  position: relative;
  z-index: 1;
  /* min-width: 88px;  */
  color: rgba(0, 0, 0, 0.87);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  border-radius: 2px;
  user-select: none;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  text-transform: none;
  padding: 0px 15px 0px 0px;
  // padding: 0;
  /* to make button occupy li a's place*/

  &:focus {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &.btn-secondary:hover {
    background-color: transparent !important;
  }

  .button-container {
    width: 100%;
    border-bottom: 1px solid;
    height: 50px;
    line-height: 50px;
    border-color: #616161 !important;

    .read-dropdown-button-span1 {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      overflow: hidden;
      pointer-events: none;
    }

    .read-dropdown-button-span2 {
      position: relative;
      /* padding-left: 16px; */
      /* padding-right: 16px; */
      vertical-align: middle;
      letter-spacing: 0px;
      /* text-transform: capitalize; */
      /* font-weight: 500; */
      /* font-size: 14px; */
      color: rgba(0, 0, 0, 0.87);

      .items-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .arrow-down {
          position: absolute;
          right: 10px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid rgb(224, 224, 224);
          margin-left: 8px;
          margin-right: -4px;
        }
      }
    }
  }
}

.navbar-dropdown-button.btn-secondary:hover {
  background-color: transparent !important;
  border: none !important;
}

.quantity-input {
  height: calc(100% - 2px) !important;
}

@media only screen and (max-width: 767px) {
  .prayer-request-card-container {
    margin: 20px;
  }

  .read-form-field {
    width: 100% !important;
  }

  .read-form-textfield {
    margin: 0 !important;
  }

  .read-dropdown-menu {
    margin-right: 0px !important;
  }
}
