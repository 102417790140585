.contactus-card-container-1 {
    margin: 20px 10px 20px 20px;
}

.contactus-card-container-2 {
    margin: 20px 20px 12px 10px;
}

.contactus-details-header {
    margin-left: 16px;
    margin-right: 16px;
}

.contactus-details-icon {
    color: #252525;
    font-size: 1.18em;
    // padding: 0.6em;
    margin: 0.6em;
    margin-left: 3px !important;
}

.contactus-details-text {
    font-size: 1em;
    padding: 0.6em;
}

.contactus-details-text a,
.contactus-details-text a:visited {
    text-decoration: none;
    color: #333333;
}

.contactus-details-text a:hover {
    text-decoration: underline;
    color: #252525;
}

.contactus-address-text {
    font-size: 1.05em;
}

.contactus-card {
    margin: 16px 0 25px 0;
}

.contactus-item {
    margin: 0.5em 0 0.5em 0;
    .link {
        text-decoration: underline !important;
        color: inherit !important;
    }
}

.contactus-item-icon {
    margin: 0 0.5em 0 1em;
}

.contactus-details-row {
    margin: 4px 0px;
}

.contactus-form {
    padding: 0 7px 0 !important;
    width: auto !important;
}

.contactus-form div,
.contactus-form div input {
    padding: 0 7px 0 !important;
}

/* parent - form field */
.contactus-form-field {
    width: 400px;
}

/* textfield */
.contactus-form-textfield {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
    width: 100% !important;
    margin-bottom: 0 !important;
    /* 100% of parent (contactus-form-field) */

    & input::placeholder {
        color: rgba(0, 0, 0, 0.3) !important;
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
        bottom: 12px;
    }

    textarea {
        width: 100% !important;
        box-shadow: none !important;
        margin: 0 !important;
        font-family: "Source Sans Pro" !important;
        font-size: 0.9em !important;
        resize: none !important;
        color: rgba(0, 0, 0, 0.87) !important;
        margin-bottom: 12px !important;

        &::placeholder {
            color: rgba(0, 0, 0, 0.3) !important;
            transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
        }
    }
}

/* textarea OLD */
// .contactus-form-textfield.textarea div textarea {
//     width: 100% !important;
//     box-shadow: none !important;
// }

/* textfield > hint OLD */
// .contactus-form-textfield div {
//     color: rgba(51, 51, 51, 0.8);
//     font-family: "Source Sans Pro" !important;
//     font-size: 0.9em;
// }

/* textfield > input */
.contactus-form-textfield input {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: "Source Sans Pro" !important;
    font-size: 0.9em;
}

.contactus-form-textfield label {
    font-family: "Source Sans Pro" !important;
}

/* icon */
.contactus-details-row .clock-icon {
    margin: 0px 8px 0px 0px;
    color: #d1d1d1;
}

/* button */
.contactus-form-button {
    margin: 0 15px 0 !important;
    width: auto !important;
    color: #333333 !important;

    /* NEW */
    text-transform: none !important;
    font-weight: 600 !important;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0px;
    user-select: none;
    padding-left: 16px;
    padding-right: 16px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 2px;
    display: inline-block;
    min-width: 88px;
    border-color: transparent !important;

    &:hover {
        background-color: rgba(0, 0, 0, 0.08) !important;
    }
}

.contactus-form-button button {
    background-color: white !important;
}

/* default, success, error button font properties */
.contactus-form-button button div div span {
    text-transform: none !important;
    font-weight: 600 !important;
}

.contactus-form-button-success button div span,
.contactus-form-button-error button div span {
    text-transform: none !important;
    font-weight: 600 !important;
}

/* success, error button styles */
.contactus-form-button-success button {
    background-color: #4caf50 !important;
}

.contactus-form-button-success button div div span {
    color: white !important;
}

.contactus-form-button-error button {
    background-color: #d32f2f !important;
}

.contactus-form-button-error button div div span {
    color: white !important;
}

/* buttons */
.contactus-form-button-success,
.contactus-form-button-error {
    margin: 0 15px 0 !important;
    width: auto !important;
    color: #424242 !important;
    opacity: 1 !important;

    /* NEW */
    text-transform: none !important;
    font-weight: 600 !important;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0px;
    user-select: none;
    padding-left: 16px;
    padding-right: 16px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: none !important;
    border-radius: 2px;
    display: inline-block;
    min-width: 88px;
    border-color: transparent !important;
}

.contactus-form-button-success {
    background-color: #4caf50 !important;
}

.contactus-form-button-error {
    background-color: #d32f2f !important;
}
/* buttons */

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none !important;
}

@media only screen and (max-width: 767px) {
    .contactus-card-container-1,
    .contactus-card-container-2 {
        margin: 20px;
    }
    .contactus-form-field {
        width: 100% !important;
    }
    .contactus-card__mobile {
        margin-bottom: 30px !important;
    }
    .contactus-title__mobile {
        margin-top: 40px !important;
    }
    .contactus-address-container__mobile {
        margin-bottom: 0px !important;
    }
    .contactus-youtube-container__mobile {
        margin-top: 12px !important;
        margin-bottom: 12px !important;
    }
    .home-contact-card__mobile {
        margin-top: 28px !important;
        margin-bottom: 0 !important;
    }
    .home-contact-title__mobile {
        margin-top: 16px !important;
    }
    .home-contact-item__mobile {
        margin-bottom: 2px !important;
    }
}
