.lazy-load-modal {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 2;
    cursor: pointer;

    .lazy-load-modal-text {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 24px;
        color: #424242;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }
}
